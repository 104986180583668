.container {
  height: 100%;
  min-height: 100vh;
}
.containerProgressbar {
  width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title {
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 12px;
  font-family: MonserratMedium;
}