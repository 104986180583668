@media screen and (orientation: landscape) {
  div.container  {
    padding-left: 30px;
    padding-right: 30px;
  }
  div.containerButton {
    bottom: 0;
    position: relative;
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-top: 16px;
    padding-bottom: 16px;
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px)  {
  @media screen and (orientation: landscape) {
    div.container {
      div.scopeContainer {
        height: 380px;
      }
      div.title {
        padding-top: 0;
      }
      div.text {
        padding-bottom: 40px;
      }
      div.circle {
        top: 80px;
      }
    }
  }
  @media(max-width: 1280px) and (max-height: 740px) {
    div.container {
      div.containerButton {
        width: 100%;
        padding-left: 6px;
        position: relative;
        div.button, div.button.buttonActive {
          position: relative;
        }
      }
    }
  }
  @media (min-width: 1260px) {
    div.containerButton {
      position: absolute;
      margin: 0;

      div.button.buttonActive {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: 0;
      }
      div.skip {
        position: absolute;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        margin-top: -50px;
        cursor: pointer;

      }
    }
    div.text {
      color: #000000;
      br {
        display: none;
      }
    }
  }
  div.glassContainer {
    img {
      width: 93px;
    }

    span {
      font-size: 2.1rem;
      padding-left: 6px;
    }
  }
  div.container {
    min-height: 100vh;
  }
  div.scopeContainer {
    height: 480px;
    background-size: 100% 100%;
    box-sizing: border-box;
  }
  div.circle {
    width: 258px;
    height: 258px;
    top: 120px;
    box-shadow: none;
    left: 16px;
    background-image: url('../../../images/main-bubble.svg');
  }
  div.whiteCircle {
    background-color: transparent;
    top: 48px;
    left: 60px;
  }
  div.title {
    font-size: 4rem;
    padding-top: 40px;
  }
  div.text {
    font-size: 1.5rem;
  }
  div.skip {
    font-size: 1.5rem;
    margin-top: -50px;
    z-index: 102;
    left: 0;
    right: 0;
    position: absolute;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  div.containerButton {
    div.buttonActive {
      width: 248px;
      height: 248px;
      background-position: center;
      background-size: contain;
      box-shadow: none;
      margin: 0 auto;
      font-size: 1.5rem;
      padding-bottom: 0;
      left: 0;
    }
  }
  img.stroke {
    position: absolute;
    top: 0;
    right: 37px;
    width: 72px;
    height: 72px;
  }
  div.scopeContainer  {
    background-image: url('../../../images/bubbles-tablet.svg');
  }
}
@media (max-width: 368px) {
  div.title {
    padding-top: 40px;
  }
}
.container {
  min-height: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #ffffff, #d6e7ff 99%);
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.back {
  font-family: MonserratMedium;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  border-radius: 11px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  display: inline-block;
  padding: 4px 7px 3px 24px;
  position: absolute;
  top: 20px;
  left: 24px;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    width: 14px;
    height: 14px;
    background-image: url('../../../images/icons/back-thin.svg');
  }
}
.starContainer {
  position: absolute;
  right: 24px;
  top: 18px;
  margin-top: 5px;
  span {
    font-family: MonserratBold;
    font-size: 1.1rem;
    line-height: 1.45;
    letter-spacing: 0.13rem;
    text-align: right;
    color: #000000;
    position: absolute;
    left: -10px;
    top: 6px;

  }
}
.header {
  padding-top: 48px;
  box-sizing: border-box;
}
.scopeContainer {
  margin-top: 20px;
  padding-top: 38px;
  padding-bottom: 60px;
  background-image: url('../../../images/handle-it-bubbles.svg');
  background-size: contain;
}
.circle {
  width: 120px;
  height: 120px;
  box-shadow: 12px 25px 30px 0 rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(to bottom, #cb3dbd, #782bdd);
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
  top: 20px;
}
.whiteCircle {
  position: absolute;
  background-color: white;
  top: 3px;
  left: 3px;
  width: 114px;
  height: 114px;
  border-radius: 100%;
}
.glassContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  span {
    font-family: MonserratBold;
    font-size: 1.5rem;
    line-height: 1.47;
    letter-spacing: 0.18rem;
    text-align: center;
    color: #3d4f6c;
  }
}
.stroke {
  position: absolute;
  top: 0px;
  right: 0;
}
.title {
  font-family: MonserratBold;
  font-size: 2.1rem;
  line-height: 1.48;
  letter-spacing: 0.25rem;
  text-align: center;
  color: #000000;
}
.text {
  padding-top: 8px;
  font-family: SFProTextRegular;
  font-size: 1.3rem;
  line-height: 1.46;
  letter-spacing: 0.15rem;
  text-align: center;
  color: #3d4f6c;
}
.containerButton {
  bottom: 48px;
  position: absolute;
  width: calc(100% - 48px)
}
.button {
  cursor: pointer;
  opacity: 0.5;
  font-family: MonserratBold;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: center;
  color: #3d4f6c;
  border-radius: 20px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  width: calc(100vw - 48px);
  box-sizing: border-box;
  width: 100%;
}
div.buttonActive {
  border-radius: 20px;
  color: white;
  opacity: 1;
  border: 0px solid transparent;
}
.skip {
  font-family: MonserratBold;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: center;
  color: #000000;
  margin-top: 16px;
  cursor: pointer;
}
.container.containerDark {
  background-image: linear-gradient(to bottom, #179aec, #0b34d9);

  .title, .text {
    color: white;
  }
  .scopeContainer  {
    background-image: url('../../../images/handle-it-bubbles-white.svg');
  }
  .back {
    border: solid 1px #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;

    &:before {
      background-image: url('../../../images/icons/back-thin-white.svg');
      background-repeat: no-repeat;
    }
  }
  .starContainer {
    span {
      color: white;
    }
  }
  .skip {
    color: white;
  }
}
