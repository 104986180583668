@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      div.title {
        padding-top: 176px;
      }
      div.contentContainer {
        display: grid;
        width: 620px;
        grid-template-columns: 372px 248px;
        margin: 0 auto;
        position: relative;
        div.termsContainer {
          width: 330px;
          margin: initial;
        }
      }
      div.buttonSubcontainer {
        position: absolute;
        bottom: -80px;
        right: 38px;
      }
    }
  }
  div.title {
    margin: 0 auto;
    width: 372px;
    padding-top: 132px;
    font-size: 6.4rem;
    line-height: 1.25;
    letter-spacing: 0.73rem;
    color: #000000;
  }
  div.termsContainer {
    width: 372px;
    margin: 0 auto;
    height: 348px;
  }
  div.buttonContainer {
    margin: 0 auto;
    margin-top: 44px;
    display: table;
  }
  div.buttonBackContainer {
    position: absolute;
    top: 30px;
    left: 24px;
  }
}
div.buttonBack {
  cursor: pointer;
  border-radius: 11px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  padding-left: 24px;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-right: 7px;
  font-family: MonserratMedium;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  position: relative;
  display: table;
  &:before {
    width: 8px;
    height: 14px;
    content: '';
    top: 3px;
    left: 9px;
    background-image: url('../../../images/icons/back-thin.svg');
    display: block;
    position: absolute;
  }
}
.container {
  box-sizing: border-box;
  padding: 20px 24px;
  background-image: linear-gradient(to bottom, #ffffff, #f0f6ff 99%);
  min-height: 100vh;
}
.title {
  font-family: MonserratBold;
  font-size: 2.7rem;
  line-height: 1.48;
  letter-spacing: 0.32rem;
  color: #000000;
}
.termsContainer {
  font-family: SFProTextRegular;
  font-size: 1.5rem;
  line-height: 1.47;
  letter-spacing: 0.2rem;
  color: #000000;
  height: calc(100vh - 240px);
  overflow-y: scroll;
}
.buttonContainer {
  padding-top: 20px;
}
div.termsContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  cursor: pointer;
}

div.termsContainer::-webkit-scrollbar:vertical {
  width: 4px;
  cursor: pointer;
}

div.termsContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
div.termsContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}