@media screen and (orientation: landscape) {
  div.positionSave {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  div.containerAnswers {
    height: 158px;
  }
  div.container {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      min-height: 100vh;
      div.genderContainer {
        margin: initial;
      }
      div.subContainerContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      div.ipadAnswers {
        padding: 0;
        overflow: hidden;
        box-sizing: border-box;
        max-height: 600px;
      }
      div.containerAnswers {
        overflow-y: auto;
        overflow-x: auto;
        height: auto;
        display: block;
        div {
          font-size: 1.5rem;
          padding: 16px;
          cursor: pointer;
          transition: 0.2s;
          &:hover {
            background-color:#0b34d91a;
          }
        }
      }
      div.subContainerContent {
        width: 1024px;
      }
      div.renderQuestionContainer {
        display: grid;
        grid-column-gap: 76px;
        grid-template-columns: 1fr 1fr;
      }
      div.subcontainer {
        width: 478px;
        div.titleAndGenreContainer {
          display: block;
        }
      }
      div.questionsContainer {
        grid-row-gap: 0;
        row-gap: 0;
      }
    }
  }
  div.container {
    background-image: linear-gradient(to bottom, #ffffff, #f0f6ff 99%);
  }
  div.subContainerContent {
    width: 440px;
    margin: 0 auto;
    padding-top: 60px;
    box-sizing: border-box;
  }
  div.positionSave {
    width: 248px;
    height: 248px;
    overflow: initial;
    position: relative;
    bottom: 0;
    margin: 0 auto;
    margin-top: 56px;
    padding: 0;
    div.saveButton {
      opacity: 1;
      border: none;
      background: none;
      box-shadow: none;
      font-size: 2.1rem;
      letter-spacing: 0.25rem;
      width: 100%;
      height: 100%;
      padding: 0;
      width: 218px;
      height: 218px;
      line-height: 160px;
      background-image: url('../../../images/get-result-filled.svg');
      background-size: contain;
      background-position: center;
      padding-left: 22px;
      box-sizing: border-box;

      &:hover {
        background-image: url('../../../images/blue-button-hovered.svg');
      }
    }
    div.saveButtonDisabled {
      background-image: url('../../../images/get-result-empty.svg');

      &:hover {
        background-image: url('../../../images/get-result-empty.svg');
      }
    }
    
  }
  div.questionsContainer {
    margin-top: 40px;
    grid-template-rows: auto;
    position: relative;
    row-gap: 32px;
  }
  div.usertitle {
    font-size: 4.8rem;
  }
  div.subtitle {
    margin-top: 10px;
    margin-bottom: 40px;
    color: #000000;
    font-size: 1.5rem;
  }
  div.ipadAnswers {
    z-index: 101;
    background-color: white;
    width: 100%;
    z-index: 100;
    padding: 16px;
    box-sizing: border-box;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    max-height: 300px;
    overflow-x: scroll;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    .containerAnswers {
      box-sizing: border-box;
      font-family: SFProTextRegular;
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;
      color: #000000;
      grid-row-gap: 32px;
      display: grid;
      grid-row-gap: 32px;
    }
  }
  div.ipadAnswers::-webkit-scrollbar {
    -webkit-appearance: none;
    cursor: pointer;
  }

  div.ipadAnswers::-webkit-scrollbar:vertical {
    width: 4px;
    cursor: pointer;
  }

  div.ipadAnswers::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    position: absolute;
  }
  div.ipadAnswers::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    position: absolute;
    height: 100px;
  }
  div.ipadAnswersClickOutsideContainer {
    display: block;
    overflow: hidden;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    width: 100%;
    position: absolute;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 368px) {
  div.container {
    div.renderQuestionContainer {
      padding-bottom: 40px;
      box-sizing: border-box;
    }
    div.positionSave {
      position: relative;
      bottom: 0;
      margin-left: 0;
      top: 24px;
    }
  }
}
.container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  position: relative;
  background-image: linear-gradient(to bottom, #ffffff, #f0f6ff 99%);
}
.containerContent {
  width: 100%;
  box-sizing: border-box;
  // position: relative;
  padding: 20px;
  padding-bottom: 100px;
}
.navigation {
  font-family: MonserratMedium;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  border-radius: 11px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  padding: 4px 10px 3px 10px;
  display: inline-block;
}
.login {
  cursor: pointer;
  padding-left: 30px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    left: 10px;
    top: 4px;
    background-image: url('../../../images/icons/back-14.svg');
  }
}
.skip {
  float: right;
  cursor: pointer;
}
.usertitle {
  font-family: MonserratBold;
  font-size: 2.7rem;
  line-height: 1.48;
  letter-spacing: 0.32rem;
  color: black;
  margin-top: 20px;
  width: 100%;
}
.subtitle {
  font-family: SFProTextRegular;
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: 0.2rem;
  color: black;
}
.questionsContainer {
  display: grid;
  margin-top: 8px;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 8px;
}
.saveButton {

  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  padding: 12px 0 12px 0;
  text-align: center;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #179aec, #0b34d9);
  font-family: MonserratBold;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: center;
  color: white;
}
.positionSave {
  position: absolute;
  bottom: 32px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin-left: -20px;
  overflow: hidden;
  min-height: 60px;
}
.containerModal {
  height: auto;
  max-height: 84vh;
  outline: transparent;
  position: absolute;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 22px 24px 6px 21px;
  box-sizing: border-box;
  width: calc(100% - 64px);
}
.modalTitle {
  font-family: MonserratBold;
  font-size: 1.5rem;
  line-height: 1.47;
  letter-spacing: 0.18rem;
  color: black;
  padding-bottom: 16px;
}
.containerAnswers {
  overflow-y: scroll;
  height: auto;
  overflow-x: hidden;
  max-height: calc(84vh - 128px);
}
.divider {
  background:#3d4f6c33;
  height: 1px;
  margin: 0 -24px 0 -21px;
  border: none;
}
.containerAnswer {
  font-family: SFProTextRegular;
  font-size: 1.5rem;
  line-height: 1.47;
  letter-spacing: 0.2rem;
  color: black;
  grid-template-columns: 24px 1fr;
  column-gap: 24px;
}
.containerModalButtons {
  margin-top: 9px;
  margin-right: -9px;
  div {
    float: right;
    margin-left: 16px;
    font-family: MonserratBold;
    font-size: 1.1rem;
    line-height: 1.45;
    letter-spacing: 0.13rem;
    color: #0b34d9;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 8px;
    cursor: pointer;
  }
}
div.saveButtonDisabled {
  border: solid 1px rgba(61, 79, 108, 0.2);
  color: #3d4f6c;
  background: none;
  box-shadow: none;
  opacity: 0.5;
}
div.acceptDisabled {
  color: #3d4f6c;
}
.genderContainer {
  display: table;
  margin: 0 auto;
}

.backdropFilter {
  backdrop-filter: blur(3px);
}
.ipadAnswers {
  position: absolute;
  z-index: 101 !important;
}
.questionDropdownContainer {
  position: relative;
}