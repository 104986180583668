
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      background-image: linear-gradient(to bottom, #ffffff, #d6e7ff 97%);
      padding-top: 48px;
      
      div.buttonContainer {
        width: 248px;
        height: 248px;
        right: 0;
        bottom: 0;
        left: auto;
      }
      div.title {
        position: absolute;
        bottom: 236px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      div.subtitle {
        position: absolute;
        bottom: 136px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
    div.imgContainer {
      img {
        margin: 0 auto;
        display: table;
        width: 100%;
      }
    }
  }
  div.container {
    background-image: linear-gradient(to bottom, #ffffff, #d6e7ff 97%);
    padding-bottom: 300px;
    box-sizing: border-box;
  }
  div.buttonContainer {
    width: 248px;
    height: 248px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 12px;
    div {
      span {
        font-size: 1.5rem !important;
        padding: 63px 10px;
      }
    }
  }
  div.title {
    font-size: 4rem;
  }
}
.container {
  background-image: linear-gradient(to bottom, #ffffff, #f0f6ff 99%);
  min-height: 100vh;
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 100px;
}
.imgContainer {
  img {
    margin: 0 auto;
    display: table;
    width: 100%;
    max-width: 621px;
  }
}
.title {
  font-family: MonserratBold;
  font-size: 2.7rem;
  line-height: 1.48;
  letter-spacing: 0.32rem;
  text-align: center;
  color: #000000;
  padding-top: 20px;
}
.subtitle {
  font-family: SFProTextRegular;
  font-size: 1.5rem;
  line-height: 1.47;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #000000;
  padding-top: 16px;
}
.buttonContainer {
  width: calc(100% - 48px);
  margin: 0 auto;
  position: absolute;
  bottom: 36px;
  left: 24px;
  span {
    text-transform: none;
  }
}
.container.containerDark {
  background-image: linear-gradient(to bottom, #179aec, #0b34d9);

  .title, .subtitle {
    color: white;
  }
}