.checkboxContainer {
  display: grid;
  grid-template-columns: 24px 1fr;
  vertical-align: middle;
  align-items: center;
  column-gap: 8px;
}
.labelCheckbox {
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-radius: 5px;
  display: block;
  cursor: pointer;
}
.checkbox {
  position: absolute;
  top: -3000px;
  visibility: hidden;
}
.checkbox:checked + label {
  background-image: url('../../../../images/auth/accept.svg');
  background-position: center;
}
.checkboxPlaceholder {
  cursor: pointer;
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
  color: white;
  font-family: SFProTextRegular;
}