div.selectForm {
  width: 100%;
  font-family: SFProTextRegular;
  font-size: 15px;

  textarea, input, label {
    font-family: SFProTextRegular;
    font-size: 15px;
    letter-spacing: 2px;
    margin-bottom: 10px;
    color: #3d4f6c;
    line-height: 0px;
  }
  div > div {
    font-family: SFProTextRegular;
    font-size: 15px;
    // margin-left: 5px;
  }
}

li.selectItem {
  font-family: SFProTextRegular;
  font-size: 15px;
}
@media screen and (orientation: landscape) {
  div.container  {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    box-sizing: border-box;
  }
  // div.containerButton {
  //   bottom: 0;
  //   position: relative;
  //   margin-top: 36px;
  // }
  .subcontainer {
    padding-left: 16px;
    padding-right: 16px;
  }
  div.containerOptions  {
    width: calc(100% - 32px);
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      div.subcontainer {
        width: 478px;
      }
      div.recoveryPasswordDropdownContainer {
        div:first-child {
          margin-top: 0;
          label {
            margin-top: -4px;
          }
          div:first-child {
            margin-top: 0;
          }
        }

      }
      div.containerOptions {
        width: 100%;
        display: block;
        padding: 0;
        div.option {
          width: 100%;
          padding: 30px 12px;
          box-sizing: border-box;
          cursor: pointer;
          &:hover {
            background-color: #0b35da1a;
          }
        }
      }
    }
  }
  @media(max-width: 1280px) {
    div.container {
      div.title {
        padding-top: 32px;
      }
    }
  }
  @media(max-width: 1280px) and (max-height: 740px) {
    div.container {
      div.subcontainer {
        padding-top: 36px;
      }
    }
  }
  div.container {
    margin: 0;
    min-height: 100vh;
  }
  div.containerOptions {
    max-height: 420px;
    div.option {
      font-size: 2.1rem;
    }
  }
  div.subcontainer {
    padding-top: 170px;
    width: 348px;
    margin: 0 auto;
  }
  div.title {
    font-size: 4rem;
    line-height: 1.48;
    letter-spacing: 0.32rem;
  }
  div.inputDescription {
    font-size: 3.9rem;
    line-height: 1.2;
    letter-spacing: 0.45rem;
  }
  div.inputContainer {
    margin-top: 12px;

    label {
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;
    }
    input, textarea {
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;
      padding-top: 12px;
    }

    textarea {
      padding-top: 4px !important;
    }
  }
  div.selectForm {
    label {
      font-size: 2.1rem;
      line-height: -1.48;
      letter-spacing: 0.25rem;
    }
    input, textarea {
      font-size: 2.1rem;
      line-height: -1.48;
      letter-spacing: 0.25rem;
      padding-top: 12px;
    }
  }
  li.selectItem {
    font-family: SFProTextRegular;
    font-size: 2.1rem;
  }
  div.description {
    padding-top: 16px;
  }
  div.inputDescription {
    margin-top: 48px;
    min-height: 140px;
  }
  img.errorImg {
    top: 42px;
  }
  div.errorMessage {
    font-size: 1.3rem;
    position: absolute;
  }
  div.containerButton {
    position: relative;
    width: 248px;
    margin: 0 auto;
    margin-top: 92px;
    div.button {
      background-image: url('../../../images/get-result-empty.svg');
      background-position: center;
      background-size: contain;
      box-shadow: none;
      width: 248px;
      height: 248px;
      border-radius: 100%;
      line-height: 134px;
      font-size: 2.1rem;
      letter-spacing: 0.25rem;
      margin: 0 auto;
      border: none;
      box-shadow: none;
      margin-left: -16px;
      padding-left: 46px;
      padding-top: 26px;
    }
    div.button.buttonActive {
      background-image: url('../../../images/get-result-filled.svg');

      &:hover {
        background-image: url('../../../images/blue-button-hovered.svg');
      }
    }
  }
  div.newAccountLink {
    font-size: 1.3rem;
    line-height: 1.46;
    letter-spacing: 0.154rem;
    margin-top: -48px;
    z-index: 101;
    cursor: pointer;
    position: absolute;
    width: 100%;
    text-align: center;
  }
}
@media (max-height: 420px) {
  div.container {
    div.containerButton {
      width: 100%;
      position: relative;
      margin-top: 30px;
      bottom: 0;
    }
  }
}
@media(max-height: 560px) {
  div.containerOptions {
    max-height: 200px;
  }
}
.container {
  box-sizing: border-box;
  min-height: 100%;
  background-image: linear-gradient(to bottom, #ffffff, #f0f6ff 99%);
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  width: 100%;
}
.back {
  cursor: pointer;
  font-family: MonserratMedium;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  border-radius: 11px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  display: inline-block;
  padding: 3px 7px 3px 24px;
  position: absolute;
  top: 20px;
  left: 24px;

  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    width: 14px;
    height: 14px;
    background-image: url('../../../images/icons/back-thin.svg');
  }
}
.title {
  font-family: MonserratBold;
  font-size: 2.7rem;
  line-height: 1;
  letter-spacing: 0.32rem;
  color: #000000;
  padding-top: 60px;
}
.description {
  padding-top: 58px;
  font-family: SFProTextRegular;
  font-size: 1.5rem;
  line-height: 1.47;
  letter-spacing: 0.2rem;
  color: #000000;
}
.inputDescription {
  margin-top: 66px;
  font-family: MonserratBold;
  font-size: 2.1rem;
  line-height: 1.48;
  letter-spacing: 0.25rem;
  color: #000000;
  position: relative;
  .MuiInput-root {
    padding-right: 24px;
  }
}
.recoveryPasswordDropdownContainer {
    cursor: pointer;
    position: relative;
}
.inputContainer {
  width: 100%;
  margin-top: 24px;
  position: relative;
  input {
    font-size: 1.5rem;
    color: black;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 10px;
    letter-spacing: 0.2rem;
    line-height: 1.47;
    &::placeholder {
      color: #3d4f6c;
    }
  }
  textarea {
    font-size: 1.5rem;
    color: black;
    padding-left: 8px;
    padding-right: 40px;
    padding-bottom: 0px;
    letter-spacing: 0.2rem;
    line-height: 1.47;
    &::placeholder {
      color: #3d4f6c;
    }
  }
  div {
    &:before {
      opacity: 0.7;
      border-bottom: 1px solid #3d4f6c !important;
    }
    &:after {
      opacity: 0.7;
      border-bottom: 2px solid #3d4f6c !important;
    }
  }
  label {
    font-size: 1.5rem;
    line-height: 1.3rem;
    letter-spacing: 0.2rem;
    font-family: SFProTextRegular;
    color: #3d4f6c !important;
    padding-left: 8px;
  }
}
.containerButton {
  bottom: 32px;
  position: absolute;
  width: calc(100% - 48px);
}
.button {
  opacity: 0.5;
  font-family: MonserratBold;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: center;
  color: #3d4f6c;
  border-radius: 20px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  width: calc(100vw - 48px);
  padding: 12px 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
  box-sizing: border-box;
}
.newAccountLink {
  font-family: MonserratBold;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: center;
  color: #0b34d9;
  padding-bottom: 12px;
  display: table;
  margin: 0 auto;
  cursor: pointer;
  margin-top: 16px;
}
div.buttonActive {
  border-radius: 20px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #179aec, #0b34d9);
  color: white;
  opacity: 1;
  border: 0px solid transparent;
  cursor: pointer;
}
.errorMessage {
  font-family: SFProTextRegular;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #e51247;
  margin-top: 2px;
  position: relative;
}
.errorImg {
  display: block;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 15px;
  position: absolute;
}
.selectDropdown {
  top: 40px;
}
.containerOptions {
  overflow-y: scroll;
  height: 0;
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 102;
  padding: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr;
  max-height: 260px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .option {
    pointer-events: auto;
    touch-action: auto;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 1.5rem;
    line-height: 1.47;
    letter-spacing: 0.2rem;
    color: #000000;
    font-family: SFProTextRegular;
    cursor: pointer;
  }
}
.securityDropdownActive {
  .containerOptions {
    height: auto;
    padding: 0 24px 0 24px;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.07);
  }
  .recoveryPasswordDropdownContainer {
    cursor: pointer;
  }
}
.securityDropdownActive + div {
  pointer-events: none;
}
.inputContainer.inputContainerError {
  label {
    color: #e51247 !important;
  }
  div {
    &:before {
      border-bottom: 2px solid #e51247 !important;
    }
  }
}