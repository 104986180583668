@media screen and (orientation: landscape) {
  div.container  {
    padding-left: 30px;
    padding-right: 30px;
  }

  .subcontainer {
    padding-left: 16px;
    padding-right: 16px;
  }
  div.containerButton {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    bottom: 0;
    div {
      width: 100%;
    }
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      padding: 0;
      div.skate {
        margin: 0 auto;
        width: 360px;
        height: 280px;
      }
    }
    div.contentText {
      br {
        display: none;
      }
    }
  }
  div.subcontainer {
    padding: 0;
  }
  div.container {
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
  }
  div.title {
    margin-top: 0;
    font-size: 4rem;
    line-height: 1.2;
    letter-spacing: 0.45rem;
    text-align: center;
    color: #000000;
    height: 220px;
  }
  div.contentText {
    font-size: 1.5rem;
    line-height: 1.47;
    letter-spacing: 2px;
    color: #000000;
  }
  div.containerButton {
    padding-top: 40px;
    width: 248px;
    height: 248px;
    bottom: 0;
    right:0;
    padding-bottom: 0;
    margin: 0 auto;
    left: 0;
    span {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: -19px;
      padding-left: 14px;
      left: 57px;
    }
  }
  div.containerButtonActive {

    div.buttonActive {
      background: none;
      background-image: none;
      border: none;
      box-shadow: none;
    }

    &:hover {
      background-image: url('../../../images/blue-button-hovered.svg');
    }
  }
  div.skate {
    margin: 0 auto;
    width: 400px;
    height: 300px;
  }
}
@media (max-width: 368px) {
  div.containerButton {
    bottom: 36px;
  }
}
.container {
  min-height: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #ffffff, #f0f6ff 99%);
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.header {
  padding-top: 20px;
  box-sizing: border-box;
}
.title {
  padding-top: 16px;
  font-family: MonserratBold;
  font-size: 2.1rem;
  line-height: 1.48;
  letter-spacing: 0.25rem;
  color: #000000;
  text-align: center;
  height: 110px;
  box-sizing: border-box;
}
.skate {
  margin-top: 46px;
  width: 264px;
  height: 184px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.skate.readyResultSkate {
  background-image: url('../../../images/skate.svg');
}
.skate.noReadyResultsSkate {
  background-image: url('../../../images/skate-dark.svg');
}
.content {
  display: table;
  margin: 0 auto;
}
.contentText {
  font-family: SFProTextRegular;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  margin-top: 32px;
  text-align: center;
}
.containerButton {
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  width: calc(100% - 48px);
  bottom: 44px;
}
.button {
  cursor: pointer;
  opacity: 0.5;
  font-family: MonserratBold;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: center;
  color: #3d4f6c;
  border-radius: 20px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  width: calc(100vw - 48px);
  padding: 12px 16px;
  box-sizing: border-box;
  box-sizing: border-box;
}
div.buttonActive {
  border-radius: 20px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #179aec, #0b34d9);
  color: white;
  opacity: 1;
  border: 0px solid transparent;
}
@media(max-height: 560px) {
  div.containerButton {
    bottom: 4px !important;
  }
}