@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      padding-top: 0;
      max-width: 356px
    }
    div.gender {
      width: 124px;
      height: 124px;
      line-height: 98px
    }
    div.genderTransparent {
      width: 124px;
      height: 124px;
      margin-left: -5px;
    }
    div.container {
      max-width: 348px;
    }
    div.male {
      background-image: url('../../../../images/male-demography-empty-tablet.svg');
    }
    .female {
      background-image: url('../../../../images/female-demography-empty.svg');
    }
    .alternative {
      background-image: url('../../../../images/alternative-demography-empty.svg');
    }
    div.genreCircleActive.male {
      background-image: url('../../../../images/male-demography-filled.svg');
    }
    div.genreCircleActive.female {
      background-image: url('../../../../images/female-demography-filled.svg');
    }
    div.genreCircleActive.alternative {
      background-image: url('../../../../images/alternative-demography-filled.svg');
    }
  }
}
@media (max-width: 368px) {
  div.container {
    max-width: 280px;

    div.gender {
      width: 80px;
      height: 80px;
      line-height: 57px;
    }
  }
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding-top: 14px;
  max-width: 324px;
  margin: 0 auto;
}
.genreCircleActive.male {
  background-image: url('../../../../images/male-demography-filled.svg');
}
.genreCircleActive.female {
  background-image: url('../../../../images/female-demography-filled.svg');
}
.genreCircleActive.alternative {
  background-image: url('../../../../images/alternative-demography-filled.svg');
}
.gender {
  width: 112px;
  height: 112px;
  background-size: contain;
  box-sizing: border-box;
  text-align: center;
  font-family: MonserratBold;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 86px;
  letter-spacing: 0.13rem;
  text-align: center;
  color: #3d4f6c;
  justify-items: center;
  position: relative;
  cursor: pointer;
}
.male {
  background-image: url('../../../../images/male-demography-empty.svg');

  &:hover {
    color: white;
    background-image: url('../../../../images/male-demography-filled.svg');
  }
}
.female {
  background-image: url('../../../../images/female-demography-empty.svg');

  &:hover {
    color: white;
    background-image: url('../../../../images/female-demography-filled.svg');
  }
}
.alternative {

  background-image: url('../../../../images/alternative-demography-empty.svg');

  &:hover {
    color: white;
    background-image: url('../../../../images/alternative-demography-filled.svg');
  }
}
div.genreActive {
  color: white;
}
.genderTransparent {
  display: block;
  margin-top: 6px;
  margin-left: -8px;
}