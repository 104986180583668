@media screen and (orientation: landscape) {
  div.containerPassword {
    min-height: 100%;
    height: auto;
  }
  div.containerLoginBackButton {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  div.loginContainer {
    min-height: 100vh;
    background-image: linear-gradient(to top, #0b34d9, #179aec);

    div.subcontainer {
      height: 100%;
      background-size: cover;
      background-image: url('../../../../images/bg-login-new-bubbles-white.svg');
    }
  }
  div.container {
    background-color: none;
  }
  div.containerPassword {
    background-image: linear-gradient(to top, #0b34d9, #179aec);
  }
}
.containerPassword {
  height: 100%;
  background: linear-gradient(#179aec, #0b34d9); 
}
.loginContainer {
  height: 100%;
}
.containerLoginBackButton {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 10;
}