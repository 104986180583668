@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        #root {
            min-height: 100vh;
        }
    }
}
html,
body {
    height: 1px;
    min-height: 100%;
    font-size: 10px;
    width: 100%;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root {
    height: 1px;
    min-height: 100%;
    width: 100%;
}
.clear_fix:after {
    content: '';
    display: table;
    clear: both;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.MuiInput-formControl {
    cursor: pointer;
}
.darkmodeSwitcherContainerGlobal .MuiSwitch-switchBase.Mui-checked .MuiIconButton-label .MuiSwitch-thumb {
    background-image: linear-gradient(to bottom, #ffffff, #e4eaf6);
    color: transparent;
}
div,
button,
span,
a,
input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}
