
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 0px) {
  @media (min-width: 1260px) {
  }
  div.container-question-slider {
    span.MuiSlider-root {
      border-radius: 22px;
    }
    span.MuiSlider-mark {
      width: 34px;
      height: 34px;
      margin-top: 5px;
      cursor: pointer;
    }
    span.MuiSlider-root[data-value='0'] {
      span.MuiSlider-mark {
        &:after {
          width: 30px;
          height: 30px;
        }
      }
    }
    span.MuiSlider-root {
      .MuiSlider-thumb[aria-valuenow='-1'] {
        left: calc(0% + 20px) !important;
      }
      .MuiSlider-thumb[aria-valuenow='1'] {
        left: calc(100% - 21px) !important;
      }
    }
    span.MuiSlider-mark[data-index='0'] {
      margin-left: 3px;
      left: 0 !important;
    }
    span.MuiSlider-mark[data-index='1'] {
      left: calc(50% - 17px) !important;
    }
    span.MuiSlider-mark[data-index='2'] {
      left: calc(100% - 37px) !important;
    }
  }
}
.container-question-slider {
  span.MuiSlider-root {
    box-sizing: border-box;
    padding: 0;
    border-radius: 14px;
    border: 1px solid transparent;
    transition: 0.3s;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.24);
    background-color: #e4eaf6;
    transition: 0.3s;

    .MuiSlider-thumb[aria-valuenow='-1'] {
      left: calc(0% + 14px) !important;
    }
    .MuiSlider-thumb[aria-valuenow='1'] {
      left: calc(100% - 14px) !important;
    }
  }
  span.MuiSlider-thumb {
    box-sizing: border-box;
  }
  span.MuiSlider-mark {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    box-sizing: border-box;
    margin-top: 2px;
    border-style:solid;
    border-width: 1px;
  }
  span.MuiSlider-mark[data-index='0'] {
    margin-left: 3px;
    left: 0 !important;
  }
  span.MuiSlider-mark[data-index='1'] {
    left: calc(50% - 11px) !important;
  }
  span.MuiSlider-mark[data-index='2'] {
    left: calc(100% - 25px) !important;
    opacity: 0.8;
    background-color: #fff;
  }
  span.MuiSlider-root[data-value='1'], span.MuiSlider-root[data-value='-1'] {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.24);
    background-image: linear-gradient(to bottom, #a40080, #e51247);
    .MuiSlider-thumb {
      box-sizing: border-box;
      border: solid 1px #ffffff;
      background-image: linear-gradient(to bottom, #ffffff, #e4eaf6);
    }

    span.MuiSlider-mark {
      opacity: 0.2;
      border: solid 1px #ffffff;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

    span.MuiSlider-root[data-value='0'] {
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.24);
      background-color: #e4eaf6;
      .MuiSlider-thumb {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.32);
        border: solid 1px #ffffff;
        background-image: linear-gradient(to bottom, #e51247, #a40080);
      }

      span.MuiSlider-mark {
        background-image: linear-gradient(to bottom, #e51247, #a40080);
        &:after {
          position: absolute;
          content: '';
          width: 18px;
          height: 18px;
          border-radius: 100%;
          left: 1px;
          top: 1px;
          background-color: #e4eaf6;
        }
      }
  }
}
.containerGlobal[data-darkmode='true'] {
  .container-question-slider {
    span.MuiSlider-root {
      border: 1px solid white;
    }
  }
}
.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
  box-shadow: none !important;
}