@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.inputTextContainer.inputDesktopStylesContainer {
      img.eyeIcon {
        position: absolute;
        bottom: 5px;
        width: 36px;
        height: 36px;
        right: 6px;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 6px;
        padding-bottom: 6px;
        box-sizing: border-box;
      }
    }
  }
  div.inputTextContainer {
    position: relative;
    img.eyeIcon {
      position: absolute;
      bottom: 18px;
      width: 24px;
      height: 24px;
      right: 16px;
    }
    div.MuiInputLabel-formControl {
      transform: translate(0, 26px) scale(1);
    }
    div.inputContainer {
      input {
        font-size: 2.1rem;
        line-height: 1.5;
        letter-spacing: 0.25rem;
        color: white;
        padding-bottom: 12px;
        padding-top: 12px;
      }
      div {
        &:before {
          border-bottom: 1px solid white !important;
        }
        &:after {
          border-bottom: 2px solid white !important;
        }
      }
      label {
        font-size: 2.1rem;
        line-height: 1.5;
        letter-spacing: 0.25rem;
        font-family: SFProTextRegular;
        color: white;
        padding-left: 16px;
      }
    }
    div {
      width: 100%;
    }
    &::placeholder {
      color: white;
    }
  }
}
div.inputTextContainer {
  position: relative;

  .inputContainer {
    input {
      font-size: 1.6rem;
      color: white;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 10px;
      letter-spacing: 0.2rem;

      &:-webkit-inner-spin-button, 
      &:-webkit-outer-spin-button { 
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0; 
      }
    }
    div {
      &:before {
        border-bottom: 1px solid white !important;
      }
      &:after {
        border-bottom: 2px solid white !important;
      }
    }
    label {
      font-size: 1.5rem;
      line-height: 1.3rem;
      letter-spacing: 0.2rem;
      font-family: SFProTextRegular;
      color: white;
      padding-left: 16px;
    }
  }
  div {
    width: 100%;
  }
  &::placeholder {
    color: white;
  }
}
.eyeIcon {
  position: absolute;
  bottom: 10px;
  width: 24px;
  height: 24px;
  right: 16px;
  cursor: pointer;
}
.inputError {
  position: absolute;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  font-size: 1.1rem;
  line-height: 1.6rem;
  letter-spacing: 0.13rem;
  color: rgb(229, 235, 255);
  padding-top: 2px;
}
input:-internal-autofill-previewed {
  background-color: transparent !important;
  color: -internal-light-dark(black, white) !important;
}

.inputHelperText {
  color: #ffffff!important;
}
