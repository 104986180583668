@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  div.backContainer {
    &:hover {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
      background-color: #ffffff;
    }
    &:focus, &:active {
      box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
      background-color: #efefef;
    }
  }
}
.backContainer {
  font-family: SFProTextRegular;
  font-size: 1.1rem;
  line-height: 1.6rem;
  letter-spacing: 0.13rem;
  color: black;
  border-radius: 12px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  padding: 4px 10px 3px 30px;
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    z-index: 10;
    left: 10px;
    top: 4px;
    position: absolute;
    background-image: url('../../../../images/auth/arrow-left.svg');
  }

  &:hover {
    box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
    background-color: #efefef;
  }
}