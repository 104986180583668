
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  div.buttonContainerDark {
    border: none;
  }
  div.buttonContainer {
    width: 248px;
    height: 248px;
    margin: 0 auto;
    position: relative;
    margin-left: -18px;
    div.activeButton.doneButton {
      background-color: transparent;
      box-shadow: none;
      background-image: url('../../../../images/light-button-enabled.svg');

      span {
        color: white;
      }
    }
    div.buttonHoverHandler {
      width: 160px;
      height: 160px;
      position: absolute;
      z-index: 100;
      cursor: pointer;
      right: 28px;
      top: 14px;
      border-radius: 100%;
    }
    div.buttonHoverHandler:hover + div.activeButton {
      opacity: 1;
      background-image: url('../../../../images/light-button-hovered.svg');

      span {
        color: white;
      }
    }
    div.doneButton {
      border: none;
      position: relative;
      width: 248px;
      height: 248px;
      background-image: url('../../../../images/light-button-disabled.svg');
      box-sizing: border-box;
      margin-top: -24px;
      span {
        cursor: pointer;
        width: 160px;
        height: 160px;
        position: absolute;
        font-family: MonserratBold;
        font-size: 2.1rem;
        line-height: 1.48;
        letter-spacing: 0.25rem;
        text-align: center;
        color: #3d4f6c;
        float: right;
        right: 29px;
        box-sizing: border-box;
        padding-top: 66px;
      }
    }
  }
}
.buttonContainer {
  position: relative;
}
div.doneButton {
  font-family: MonserratBold;
  font-size: 1.1rem;
  line-height: 1.6rem;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  opacity: 0.7;
  width: 100%;
  border-radius: 24px;
  border: 1px solid white;
  padding: 12px 0 10px 0;
  text-transform: capitalize;

  border-radius: 20px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  span {
    text-align: center;
    margin: 0 auto;
    display: block;
  }
}
div.activeButton {
  color: white;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #179aec, #0b34d9);
  opacity: 1;
  border-top: none;
  &:hover {
    box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to bottom, #0085d9, #0029cc);
  }
}
.buttonHoverHandler {
  width: 100%;
  height: 40px;
  bottom: 0;
  position: absolute;
}
.buttonContainerDark {
  opacity: 0.7;
  border-radius: 20px;
  border: solid 1px #ffffffb3;

  span {
    color: white;
  }
}
.buttonContainerActive.buttonContainerDark {
  opacity: 1;
  .doneButton {
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    background-image: none;

    span {
      color: black;
    }
  }
}