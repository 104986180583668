@media screen and (orientation: landscape) {
  div.container  {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      div.title {
        padding-top: 176px;
      }
    }
  }
  @media(max-width: 1260px) and (max-height: 740px) {
    div.container {
      div.whyContainer {
        bottom: 16px;
      }
    }
  }
  div.container {
    min-height: 100vh;
  }
  div.title {
    margin-top: 250px;
    text-align: center;
    font-size: 4rem;
    line-height: 1.2;
    letter-spacing: 0.45rem;
    padding-top: 250px;
    margin-top: 0;
  }
  div.circle {
    width: 160px;
    height: 160px;
    
    span {
      top: 73px;
      left: 76px;
      font-size: 2.1rem;
      line-height: 1.05;
      letter-spacing: 0.252rem;
    }
  }
  div.whiteCircle {
    width: 154px;
    height: 154px;
  }
  div.whyContainer {
    width: 350px;
    text-align: center;
    margin: 0 auto;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    bottom: 76px;
  }
  div.activeWhyText {
    width: 350px;
    text-align: center;
    margin: 0 auto;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
  }
}
.container {
  min-height: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #ffffff, #f0f6ff 99%);
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}
.containerTitle {
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-top: 12px;
}
.backContainer {
  font-family: MonserratMedium;
  font-size: 1.1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  padding: 3px 10px 3px 30px;
  border-radius: 11px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    width: 14px;
    height: 14px;
    background-image: url('../../../images/icons/back-14.svg');
  }
}
.title {
  margin-top: 20px;
  font-family: MonserratBold;
  font-size: 2.1rem;
  line-height: 1.48;
  letter-spacing: 0.25rem;
  color: #000000;
}
.circleContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  margin: 0 auto;
  margin-top: 48px;
}
.circle {
  width: 136px;
  height: 136px;
  position: relative;

  span {
    position: absolute;
    top: 64px;
    left: 66px;
    transform: translate(-50%, -50%);
    font-family: MonserratBold;
    font-size: 1.5rem;
    line-height: 1.47;
    letter-spacing: 0.18rem;
    text-align: center;
    color: #3d4f6c;
  }
}
div.yesCircle {
  background-image: url('../../../images/icons/yes-disabled.svg');
  background-size: 100%;
  cursor: pointer;
  &:active, &:hover {
    background-image: url('../../../images/icons/yes-enabled.svg');

    span {
      color: white;
    }
  }
}
div.noCircle {
  margin-top: 48px;
  background-image: url('../../../images/icons/no-disabled.svg');
  background-size: 100%;
  cursor: pointer;
  &:active, &:hover {
    background-image: url('../../../images/icons/no-enabled.svg');

    span {
      color: white;
    }
  }
}
.whiteCircle {
  width: 136px;
  height: 136px;
  margin-top: 3px;
  border-radius: 100%;
  margin-left: 3px;
}
.circleActive {
  .whiteCircle {
    background: transparent;

    span {
      color: white;
    }
  }
}
.whyContainer {
  position: absolute;
  bottom: 32px;
  width: calc(100% - 48px);
  .whyContainerTitle {
    cursor: pointer;
    font-family: MonserratBold;
    font-size: 1.1rem;
    line-height: 1.45;
    letter-spacing: 0.13rem;
    color: #3d4f6c;
    position: relative;
    padding-right: 22px;
    box-sizing: border-box;
    display: inline-block;
    padding-bottom: 8px;
    &:after {
      transition: 0.3s;
      position: absolute;
      content:  '';
      right: 0;
      top: 0;
      width: 14px;
      height: 14px;
      background-image: url('../../../images/icons/arrow-down.svg');
    }
  }
}
.whyText {
  font-family: SFProTextRegular;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  height: 0;
  overflow: hidden;
}
div.activeWhyText {
  height: auto;
}
div.activeWhyTitle {
  &:after {
    transform: rotate(180deg);
  }
}
.navigation {
  font-family: MonserratMedium;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  border-radius: 11px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  padding: 4px 10px 3px 10px;
  display: inline-block;
}
.login {
  top: 20px;
  padding-left: 30px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    left: 10px;
    top: 4px;
    background-image: url('../../../images/icons/back-14.svg');
  }
}