.container {
  font-family: SFProTextRegular;
  font-size: 1.5rem;
  line-height: 1.47;
  letter-spacing: 0.2rem;
  color: black;
  display: table;
  padding-top: 15.5px;
  padding-bottom: 15.5px;

  .checkbox {
    position: absolute;
    top: -3000px;
    visibility: hidden;
  }
  .labelCheckbox {
    position: relative;
    width: 18px;
    height: 18px;
    background-color: #3d4f6c;
    border-radius: 100%;
    display: block;
  }

  .checkbox:checked + .labelCheckbox {
    background: linear-gradient(to bottom, #179aec, #0b34d9);
  }

  .checkbox:checked + label {
    .checkedDiv {
      width: 10px;
      height: 10px;
      background: linear-gradient(to bottom, #179aec, #0b34d9);
      border-radius: 100%;
      margin-top: 2px;
      margin-left: 2px;
    }
  }
  .bgWhite {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 2px;
    left: 2px;
    background-color: white;
    border-radius: 100%;
  }

  .containerInput {
    display: table-cell;
    vertical-align: middle;
  }

  .containerText {
    display: table-cell;
    vertical-align: middle;

    div {
      margin-left: 24px;
    }
  }
}