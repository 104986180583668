@media (min-width: 768px) and (max-width: 1200px) {
  @media (min-height: 768px) {
    div.question {
      font-size: 2.1rem;
    }
  }
}
.question {
  opacity: 0.8;
  font-family: SFProTextRegular;
  font-size: 1.5rem;
  line-height: 1.47;
  letter-spacing: 0.2rem;
  color: #3d4f6c;
  padding-bottom: 10px;
  border-bottom: 1px solid #3d4f6cb3;
  position: relative;
  padding-top: 14px;
  padding-right: 34px;
  padding-left: 8px;
  padding: 14px 34px 10px 8px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    right: 16px;
    top: 18px;
    background-image: url('../../../../images/icons/arrow-down.svg');
  }
}
div.answeredQuestion {
  color: #000000;
  border-bottom: 2px solid black;
  margin-bottom: -1px;
  text-transform: capitalize;

  &:after {
    background-image: url('../../../../images/icons/arrow-down-black.svg');
  }
}