@media screen and (orientation: landscape) {
  div.containerPassword {
    min-height: 100%;
    height: auto;
  }
  div.container {
    width: 100%;
    height: fit-content;
  }
  div.containerLoginBackButton {
    left: 20px;
  }
  div.containerLoginBackButton, div.containerRegisterUsername {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.subcontainer {
      background-image: url('../../../../images/bg-desktop-login.svg') !important;
    }
  }
  div.container {
    background-image: linear-gradient(to top, #7430f8, #315bf2);

    div.subcontainer {
      height: 100vh;
      background-size: cover;
      background-image: url('../../../../images/bg-login-new-bubbles-white.svg');
    }
  }
}
@media (max-height: 420px) {
  div.container {
    padding-bottom: 140px;
  }
}
.container {
  min-height: 100%;
  background-image: url('../../../../images/auth/Bg-Login-New.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  @media screen and (max-width: 740px) {
    height: auto;
    min-height: 100vh;
  }
}
.containerCustomize {
  @media screen and (max-width: 740px) {
    height: 100%;
  }
}
.containerPassword {
  min-height: 100%;
  background: linear-gradient(#315bf2, #7430f8);
}
.subcontainer {
  min-height: 100vh !important;
  height: 100% !important;
}
.containerRegisterUsername {
  height: 100%;
  min-height: 100%;
}
.containerLoginBackButton {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 10;
}
