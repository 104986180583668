@media screen and (orientation: landscape) {
  div.container  {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  div.containerButton {
    position: relative;
    margin-top: 36px;
  }
  .subcontainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      div.subcontainer {
        column-gap: 160px;
        max-width: 920px;
      }
    }
  }
  @media(max-width: 1260px) {
    div.container {
      padding-top: 100px !important;
    }
  }
  @media screen and (orientation: landscape) {
    div.backContainer {
      left: 32px;
    }
  }
  div.backContainer {
    position: absolute;
    top: 32px;
  }
  div.container {
    min-height: 100vh;
    padding-top: 250px;
    box-sizing: border-box;
  }
  div.subcontainer {
    display: grid;
    grid-template-columns: 1fr 280px;
    max-width: 648px;
    margin: 0 auto;
  }
  div.titleContainer {
    font-size: 4rem;
    line-height: 1.5;
    letter-spacing: 0.48rem;
    text-align: left;
    font-family: MonserratBold;
  }
  div.inputContainer {
    margin-top: 12px;

    label {
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;
    }
    input {
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;
      padding-top: 12px;
    }
  }
  div.buttonContainer {
    margin-top: 12px;
    padding-top: 0;
    grid-row-gap: 24px;
    row-gap: 24px;
    grid-column-gap: 24px;
    column-gap: 24px;
  }
  div.containerButton {
    position: relative;
    padding-top: 64px;
    width: 248px;
    height: 248px;
    margin: 0 auto;
    margin-top: 64px;
    div.button {
      width: 160px;
      height: 160px;
      border-radius: 100%;
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;
      line-height: 135px;

    }
  }
}
.container {
  min-height: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #ffffff, #f0f6ff 99%);
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}
.containerTitle {
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-top: 12px;
}
.titleContainer {
  float: right;
  text-align: right;
  width: 100%;
  font-family: SFProTextRegular;
  font-size: 1.3rem;
  line-height: 1.46;
  letter-spacing: 0.15rem;
  text-align: right;
  color: #000000;
}
.backContainer {
  font-family: MonserratMedium;
  font-size: 1.1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  color: #3d4f6c;
  padding: 3px 10px 3px 30px;
  border-radius: 11px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    width: 14px;
    height: 14px;
    background-image: url('../../../images/icons/back-14.svg');
  }
}
.buttonContainer {
  display: grid;
  grid-template-columns: 56px 56px 56px;
  justify-items: center;
  padding-top: 24px;
  row-gap: 16px;
  column-gap: 16px;
  align-items: center;
  width: 200px;
  margin: 0 auto;

  span {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    font-family: MonserratBold;
    font-size: 2.1rem;
    line-height: 1.48;
    letter-spacing: 0.25rem;
    text-align: center;
    color: #ffffff;
  }

  img {
    cursor: pointer;
  }
}
.number {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 32px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to top, #0b34d9, #179aec);
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to top, #0b34d9, #179aec);
  }
  &:active, &:focus {
    box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to bottom, #0085d9, #0029cc);
  }
}
.inputContainer {
  width: 100%;
  margin-top: 10px;
  position: relative;
  input {
    font-size: 1.5rem;
    color: black;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 10px;
    letter-spacing: 0.2rem;
    &::placeholder {
      color: #3d4f6c;
    }
  }
  div {
    &:before {
      opacity: 0.7;
      border-bottom: 1px solid #3d4f6c !important;
    }
    &:after {
      opacity: 0.7;
      border-bottom: 2px solid #3d4f6c !important;
    }
  }
  label {
    font-size: 1.5rem;
    line-height: 1.3rem;
    letter-spacing: 0.2rem;
    font-family: SFProTextRegular;
    color: #3d4f6c !important;
    padding-left: 8px;
  }
}
.eyeIcon {
  position: absolute;
  bottom: 10px;
  width: 24px;
  height: 24px;
  right: 16px;
  cursor: pointer;
}
.containerButton {
  bottom: 44px;
  position: absolute;
}
.button {
  opacity: 0.5;
  font-family: MonserratBold;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: center;
  color: #3d4f6c;
  border-radius: 20px;
  border: solid 1px rgba(61, 79, 108, 0.2);
  width: calc(100vw - 48px);
  padding: 12px 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
  box-sizing: border-box;
}
div.buttonActive {
  border-radius: 20px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #179aec, #0b34d9);
  color: white;
  opacity: 1;
  border: 0px solid transparent;
  cursor: pointer;
}