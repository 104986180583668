@media (min-width: 740px) and (max-width: 1366px) {
  @media(min-height: 740px) and (max-height: 1366px) {
    div.container {
      min-height: 100vh;
      height: fit-content;
    }
  }
}
.container {
  min-height: fit-content;
  height: 100%;
}