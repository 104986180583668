@media screen and (orientation: landscape) {
  div.panel {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    min-height: fit-content;
    width: 320px;
  }
  .container {
    div.panelHidden {
      left: -110vw;
    }
  }
  div.listButtons {
    padding-top: 42px;
  }
}

@supports (-webkit-touch-callout: none) { 
  div.listButtons {
    padding-bottom: 164px;
  } 
}
.container {
  img, .button {
    cursor: pointer;
  }
}
.panel {
  height: 100vh;
  width: 280px;
  position: fixed;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  z-index: 1000;
  transition: 0.3s;
}
.panelContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
}
.listButtons {
  height: 100%;
  padding-top: 82px;
  display: grid;
  grid-template-rows: 22px 22px 22px 1fr 22px;
  padding-bottom: 64px;
  padding-left: 24px;
  box-sizing: border-box;
  grid-row-gap: 30px;
  padding-right: 24px;
}
.button {
  font-family: MonserratBold;
  font-size: 1.5rem;
  line-height: 1.47;
  letter-spacing: 0.18rem;
  color: #000000;
}
div.panelHidden {
  left: -110vw;
}
.darkmodeSwitcherContainer {
  float: right;
  margin-top: -2px;
}
.backdrop {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.buttonTerms {
  display: table-cell;
  vertical-align: bottom;
  align-self: end;
}