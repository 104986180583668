@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.arrowButton {
      font-size: 1.3rem;
      cursor: pointer;
    }
  }
  div.welcomeButtonTitle {
    font-size: 1.1rem;
    letter-spacing: 0.13rem;
    text-align: center;
    color: #000000;
    width: 164px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
      background-color: #efefef;
    }
  }
}
.welcomeButtonContainer {
  align-items: center;
  display: flex;
}
.welcomeButtonTitle {
  cursor: pointer;
  line-height: 1.6rem;
  font-size: 1.1rem;
  letter-spacing: 0.13rem;
  font-family: MonserratBold;
  margin-right: 8px;
  color: white;
}
button.welcomeButton {
  cursor: pointer;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  padding: 0;
  min-width: auto;
  background-image: url('../../../../images/auth/next.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  &:active {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
  }
}