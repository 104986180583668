@media screen and (orientation: landscape) {
  div.saveButtonContainer {
    position: relative;
    width: 100%;
    bottom: auto;
    margin-top: 48px;
  }
  div.backContainer {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    left: 20px;
  }
  div.container {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    margin-left: 18px;
    margin-right: 18px;
  }
  div.inputContainer {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media(orientation: landscape) {
    div.container {
      height: 100vh;
      margin-left: 0;
      margin-right: 0;

      div.ipadPasswordContainer {
        padding-top: 86px;
        max-width: 680px;
        margin: 0 auto;
      }
    }
  }
  @media (min-width: 1260px) {
    div.container {
      background-image: url('../../../../images/bg-desktop-login.svg') !important;
      div.ipadPasswordContainer {
        padding-top: 146px;
        max-width: 868px;
        div.ipadPasswordTitle {
          padding-top: 48px;
          br {
            &:nth-child(2) {
              display: none;
            }
          }
        }
        div.passwordInputContainer {
          max-width: 448px;
        }
      }
    }
  }
  @media (max-width: 1280px) and (max-height: 740px) {
    div.container {
      background-image: url('../../../../images/bg-desktop-login.svg') !important;
      div.ipadPasswordContainer {
        padding-top: 36px;
      }
    }
  }
  div.forgotPassword {
    margin-top: -40px;
    z-index: 100;
    position: absolute;
  }
  div.container {
    height: 100vh;
    background-image: url('../../../../images/bg-tablet-password.svg');
    background-size: cover;
  }
  div.ipadPasswordContainer {
    display: grid;
    grid-template-columns: 1fr 240px;
    padding-top: 250px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    column-gap: 32px;
    max-width: 686px;
    margin: 0 auto;
  }
  div.buttonContainer {
    max-width: 240px;
  }
  div.ipadPasswordTitle {
    font-size: 4rem;
    line-height: 1.5;
    letter-spacing: 0.48rem;
    color: white;
    font-family: MonserratBold;
  }
  div.passwordInputContainer {
    margin-top: 24px;
    max-width: 316px;
  }
  div.saveButtonContainer {
    position: relative;
    bottom: initial;
    width: 248px;
    height: 248px;
    padding-top: 64px;
    margin: 0 auto;
    
    button {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;
    }
  }
  div.number {
    &:active, &:focus {
      box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
      background-color: #efefef !important;
    }
    &:hover {
      box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
      background-color: white;
    }
  }
}
@media (max-width: 368px) {
  div.container {
    padding: 8px 36px 8px 36px;
  }
  div.saveButtonContainer {
    width: calc(100% - 64px);
  }
  div.number {
    width: 48px;
    height: 48px;
    line-height: 48px;
    padding-left: 2px;
    box-sizing: border-box;
  }
  div.inputContainer {
    padding-bottom: 64px;
  }
}
.container {
  min-height: 100%; 
  padding: 18px 24px 14px 24px;
  box-sizing: border-box;
}
.titleContainer {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.titleText {
  font-size: 1.3rem;
  line-height: 19px;
  letter-spacing: 0.15rem;
  color: white;
  font-family: SFProTextBold;
  text-align: right;
  width: 100%;
}
.backImg {
  width: 24px;
  height: 24px;
}
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  margin-top: 24px;
  row-gap: 16px;
  column-gap: 16px;
  align-items: center;
}
.number {
  cursor: pointer;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background-color: white;
  line-height: 64px;
  text-align: center;
  color: black;
  font-family: MonserratBold;
  font-size: 2.1rem;
  letter-spacing: 0.25rem;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  user-select: none;

  img {
    cursor: pointer;
    box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
  }

  &:hover {
    box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
    background-color: #efefef;
  }
}
@media(max-height: 560px) {
  div.saveButtonContainer {
    bottom: 4px !important;
  }
  .inputContainer {
    margin-top: 4px;
  }
}
.inputContainer {
  margin-top: 12px;
}
.saveButtonContainer {
  position: absolute;
  bottom: 76px;
  width: 100%;
  width: calc(100% - 48px);
}
.forgotPassword {
  size: 1.1rem;
  text-align: center;
  width: 100%;
  line-height: 1.6rem;
  color: white;
  letter-spacing: 0.13rem;
  font-family: MonserratBold;
  margin-top: 10px;
  cursor: pointer;
}
.backContainer {
  position: absolute;
  top: 24px;
  left: 20px;
}

.generatedUsername {
  position: absolute;
  width: 100%;
  top: 30px;
  left: 0;
  right: 0;
  line-height: 1.5;
  letter-spacing: 0.48rem;
  color: #61dafb;
  font-family: MonserratBold;
  font-size: 2rem;
  text-align: center;
  @media screen and (max-width: 740px) {
    top: initial;
    bottom: 100px;
    font-size: 1.6rem;
  }
  &>span {
    color: #ffffff;
    font-weight: bold;
    font-size: 3rem;
    @media screen and (max-width: 740px) {
      font-size: 2rem;
    }
  }
}
