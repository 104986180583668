@media screen and (orientation: landscape) {
  div.block {
    top: 0;
  }
  div.buttonContainer {
    bottom: 0;
    margin-top: 80px;
    padding-bottom: 12px;
    position: relative;
  }
  div.title {
    text-align: center;
    padding-top: 18px;
  }
  div.text {
    margin-top: 0;
  }
  div.containerInput {
    margin-top: 0;
    position: relative;
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media(orientation: landscape) {
    .container {
      div.block {
        top: 24px;
      }
      div.buttonContainer {
        top: 136px;

        div.checkboxContainer {
          margin-bottom: 50px;
        }
      }
      div.title {
        padding-top: 0;
      }
    }
  }
  @media(min-width: 1260px) {
    div.container {
      height: auto;
      display: table;
      padding-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      div.checkboxContainer {
        cursor: pointer;
      }
      div.title {
        font-size: 6.4rem;
      }
      div.text {
        width: 376px;
      }
      div.containerInput {
        width: 376px;
      }
      div.block {
        width: 448px;
        margin: 0 auto;

        div.title {
          width: 100%;
          text-align: left;
        }

        div.text {
          width: 448px;
        }
        div.containerInput {
          width: 448px;
        }
      }
      div.buttonContainer {
        width: 448px;
      }
    }
  }
  @media(max-width: 1260px) and (max-height: 740px) {
    div.container {
      height: auto;
      display: table;
      padding-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - 50px));

      div.checkboxContainer {
        cursor: pointer;
        margin-bottom: 10px;
      }
      div.buttonContainer {
        top: 90px;
      }
    }
  }
  div.continueButtonContainer {
    display: table;
    margin: 0 auto;
  }
  div.block {
    top: 130px;
  }
  div.title {
    font-size: 5.6rem;
    line-height: 1.71;
    letter-spacing: 0.639rem;
    color: #ffffff;
    text-align: center;
  }
  div.text {
    width: 350px;
    margin: 0 auto;
    font-size: 1.5rem;
    line-height: 1.47;
    letter-spacing: 0.2rem;
    color: #ffffff;
  }
  div.containerInput {
    width: 350px;
    margin: 0 auto;
    padding-top: 24px;
  }
  div.inputError {
    font-size: 1.3rem;
  }
  div.buttonContainer {
    bottom: initial;
    top: 212px;
    position: relative;
    width: 350px;
    margin: 0 auto;

    div.checkboxContainer {
      margin-bottom: 64px;
    }

    button {
      width: 160px;
      height: 160px;
      border-radius: 100%;
      margin: 0 auto;
      display: table;
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;
    }
  }
}
@media (max-height: 420px) {
  div.container {
    div.buttonContainer {
      position: relative;
      margin-top: 140px;
      bottom: 0;
    }
  }
}
.container {
  min-height: 100vh;
  height: 100%;
  margin: 0 32px 0 32px;
  box-sizing: border-box;
  display: flex!important;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 25px;
  position: initial !important;
  transform: none !important;
  @media screen and (max-width: 740px) {
    padding-top: 50px;
  }
}
.block {
  //position: relative;
  //top: 56px;
}
.title {
  font-family: MonserratBold;
  font-size: 2.7rem;
  line-height: 4rem;
  letter-spacing: 0.32rem;
  color: white;
}
.text {
  font-size: 1.3rem;
  line-height: 1.9rem;
  letter-spacing: 0.15rem;
  font-family: SFProTextRegular;
  color: white;
  margin-top: 16px;
}
.containerInput {
  margin-top: 48px;
  position: relative;
  @media screen and (max-width: 740px){
    margin-top: 15px;
  }
}
.inputError {
  position: absolute;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  font-size: 1.1rem;
  line-height: 1.6rem;
  letter-spacing: 0.13rem;
  color: rgb(229, 235, 255);
  padding-top: 2px;
}
.buttonContainer {
  position: initial !important;
  width: 100%;
  bottom: 40px;
}
.containerPassword {
  height: 100%;
}
.checkboxContainer {
  margin-bottom: 16px;
  width: 100%;
}
.underline {
  text-decoration: underline;
}
.customInputField {
  label {
    font-size: 1.6rem !important;
    line-height: 1.6rem !important;
    top: -10px;
    padding-left: 0 !important;
  }
}
.customInputContainer {
  margin-bottom: 55px !important;
}
