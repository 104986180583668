@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) {
  div.containerWelcome {
    background-image: url('../../../images/welcome-bubble-desktop.svg');
    min-height: 100vh;
  }
  div.containerWelcomeText {
    display: table;
    width: 348px; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 32px));
    text-align: center;
    margin: 0;
    h1.welcomeText {
      font-size: 3.2rem;
      line-height: 1.25;
      letter-spacing: 0.379rem;
      text-align: center;
      margin-bottom: 14px;
    }
    h2.welcomeSubText {
      font-size: 2.1rem;
      line-height: 1.5;
      letter-spacing: 0.252rem;
      text-align: center;
      color: #ffffff;
    }
  }
  div.buttonContainer {
    position: absolute;
    top: calc(50% + 76px);
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    bottom: initial;
    right: initial;

    button {
      display: none;
    }
  }
}
.containerWelcome {
  color: white;
  height: 100%;
  display: grid;
  grid-auto-rows: auto;
  background-image: url('../../../images/auth/Purp-Blue Mask.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
.containerWelcomeText {
  margin: 160px 0 0 36px;
}
.welcomeText {
  font-size: 2.7rem;
  line-height: 4rem;
  letter-spacing: 0.32rem;
  font-family: MonserratBold;
  margin-bottom: 6px;
}
.welcomeSubText {
  font-size: 1.5rem;
  font-family: SFProTextRegular;
  line-height: 2.2rem;
  letter-spacing: 0.2rem;
}
.buttonContainer {
  position: absolute;
  bottom: 32px;
  right: 32px;
}
.welcomeButtonTitle {
  line-height: 1.6rem;
  font-size: 1.1rem;
  letter-spacing: 0.13rem;
  font-family: MonserratBold;
  margin-right: 8px;
}
button.welcomeButton {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  padding: 0;
  min-width: auto;
  background-image: url('../../../images/auth/next.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}
