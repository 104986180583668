@media screen and (orientation: landscape) {
  div.container {
    padding-top: 20px;
    background-color: none;
  }
}
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media(orientation: landscape) {
    div.container {
      padding-top: 128px;
      background-image: url('../../../../images/bg-desktop-login.svg') !important;
    }
  }
  @media (min-width: 1260px) {
    div.container {
      div.title {
        font-size: 6.4rem;
      }
    }
  }
  div.container {
    min-height: 100vh;
    background-image: url('../../../../images/bg-login-ex-bubbles-white.svg');
  }
  div.title {
    width: 450px;
    font-size: 5.6rem;
    line-height: 1.29;
    letter-spacing: 0.667rem;
  }
  div.containerTextField {
    padding-bottom: 72px;
  }
  div.buttonContainer {
    margin-top: 72px;
    margin: 0 auto;
    position: relative;
    display: table;
    button {
      margin-top: 37px;
      margin-right: 39px;
      float: right;
      border: none;
      width: 160px;
      height: 160px;
      border-radius: 100%;
      margin: 0 auto;
      font-size: 2.1rem;
      line-height: 1.48;
      letter-spacing: 0.25rem;

      span {
        margin-top: 23px;
        margin-right: 59px;
      }
    }
  }
}
@media (max-width: 368px) {
  div.title {
    width: 220px;
  }
}
.container {
  height: 100%;
  background-image: url('../../../../images/auth/Bg-Login-Exiting.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 60px;
  margin-bottom: -60px;
  box-sizing: border-box;
}
.block {
  position: absolute;
  display: grid;
  top: 15vh;
  width: 100%;
  row-gap: 80px;
  justify-content: center;
  @media screen and (max-width: 740px){
    top: 10vh;
  }
}
.title {
  font-family: MonserratBold;
  font-size: 2.7rem;
  line-height: 4rem;
  letter-spacing: 0.32rem;
  color: white;
  width: 300px;
}
.containerTextField {
  position: relative;
  input {
    letter-spacing: 0.2rem;
  }
}
.buttonContainer {
  margin-top: 56px;
}
.reminder {
  color: white;
  font-family: MonserratBold;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.2rem;
  max-width: 450px;
  font-weight: 400;
  font-style: italic;
  &>span {
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
