.bubbleContainer {
  transform: translate(0, 0);
  transform-origin: center;

  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: bubble-move;
}

.bubbleContainer4 {
  animation-delay: -3s;
}
.bubbleContainer7 {
  animation-delay: -1.5s;
}
.bubbleContainer10 {
  animation-delay: -4.5s;
}

.bubbleContainer2 {
  animation-delay: -0.5s;
}
.bubbleContainer6 {
  animation-delay: -2s;
}
.bubbleContainer11 {
  animation-delay: -3.5s;
}

.bubbleContainer3 {
  animation-delay: -0.1s;
}
.bubbleContainer8 {
  animation-delay: -1s;
}
.bubbleContainer12 {
  animation-delay: -2.6s;
}

.bubbleContainer5 {
  animation-delay: -1s;
}

.bubbleContainer9 {
  animation-delay: -3s;
}


.bubble {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 120px;

  border-radius: 45% 55% 62% 38% / 53% 51% 43% 47%;

  box-shadow: 12px 25px 30px 0 rgba(0, 0, 0, 0.3);

  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  .backdrop {
    display: none;
  }

  .bubbleText {
    font-family: MonserratBold;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 1.8px;
    color: #ffffff;
    z-index: 1;
    text-align: center;
  }

  .done & {
    .backdrop {
      display: block;
      position: absolute;
      border-radius: inherit;
      background-image: none;
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      width: inherit;
      height: inherit;
      transform-origin: center center;
      transform: scale(0.95);
      z-index: 0;
    }

    .bubbleText {
      color: #3d4f6c;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .bubble, .bubbleContainer {
    animation: none;
  }
}

.bubble1,
.bubble4,
.bubble7,
.bubble10 {
  border-radius: 53% 47% 45% 55% / 52% 63% 37% 48%;
  animation-name: bubble-wave1;

  background-image: linear-gradient(to bottom, #f20c46, #a40080);
}
.bubble4 {
  animation-delay: -3s;
}
.bubble7 {
  animation-delay: -1.5s;
}
.bubble10 {
  animation-delay: -4.5s;
}

.bubble2,
.bubble6,
.bubble11 {
  border-radius: 49% 51% 54% 46% / 42% 57% 43% 58%;
  animation-name: bubble-wave2;

  background-image: linear-gradient(to bottom, #7430f8, #315bf2);
}
.bubble6 {
  animation-delay: -2s;
}
.bubble11 {
  animation-delay: -3.5s;
}

.bubble3,
.bubble8,
.bubble12 {
  border-radius: 51% 49% 59% 41% / 51% 51% 49% 49%;
  animation-name: bubble-wave3;

  background-image: linear-gradient(to bottom, #179aec, #0b34d9);
}
.bubble8 {
  animation-delay: -1s;
}
.bubble12 {
  animation-delay: -2.6s;
}

.bubble5,
.bubble9 {
  border-radius: 57% 43% 50% 50% / 42% 53% 47% 58%;
  animation-name: bubble-wave4;

  background-image: linear-gradient(to bottom, #782bdd, #cb3dbd);
}

.bubble9 {
  animation-delay: -3s;
}

.bubbleContent {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  border-radius: 100%;
}

@media (min-width: 740px) and (max-width: 1366px) {
  @media (min-height: 740px) and (max-height: 1366px) {
    .bubble {
      width: 170px;
      height: 170px;
      &.done::after {
        width: 170px;
        height: 170px;
      }
    }
    .bubbleText {
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}

@keyframes bubble-wave1 {
  20% {
    border-radius: 57% 43% 53% 47% / 55% 59% 41% 45%;
  }
  40% {
    border-radius: 44% 56% 60% 40% / 60% 48% 52% 40%;
  }
  60% {
    border-radius: 41% 59% 47% 53% / 40% 52% 48% 60%;
  }
  80% {
    border-radius: 55% 45% 48% 52% / 51% 44% 56% 49%;
  }
}

@keyframes bubble-wave2 {
  20% {
    border-radius: 55% 45% 44% 56% / 42% 58% 42% 58%;
  }
  40% {
    border-radius: 59% 41% 49% 51% / 56% 41% 59% 44%;
  }
  60% {
    border-radius: 40% 60% 43% 57% / 42% 49% 51% 58%;
  }
  80% {
    border-radius: 47% 53% 51% 49% / 56% 53% 47% 44%;
  }
}

@keyframes bubble-wave3 {
  20% {
    border-radius: 46% 54% 47% 53% / 40% 52% 48% 60%;
  }
  40% {
    border-radius: 55% 45% 51% 49% / 49% 42% 58% 51%;
  }
  60% {
    border-radius: 51% 49% 43% 57% / 52% 52% 48% 48%;
  }
  80% {
    border-radius: 51% 49% 59% 41% / 57% 54% 46% 43%;
  }
}

@keyframes bubble-wave4 {
  20% {
    border-radius: 65% 35% 42% 58% / 50% 40% 60% 50%;
  }
  40% {
    border-radius: 56% 44% 37% 63% / 55% 53% 47% 45%;
  }
  60% {
    border-radius: 49% 51% 49% 51% / 60% 57% 43% 40%;
  }
  80% {
    border-radius: 55% 45% 61% 39% / 55% 48% 52% 45%;
  }
}


@keyframes bubble-move {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(5px); }
}
