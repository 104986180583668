@media screen and (orientation: landscape) {
  .container, div.fixedPanel  {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  div.subContainerBubbles {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    box-sizing: border-box;
  }
}
@media (min-width: 740px) and (max-width: 1260px) {
  @media(min-height: 740px) and (max-height: 1260px) {
    div.yourProgressTitle {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.47;
      letter-spacing: 0.2rem;
      color: #000000; 
    }
    div.container {
      padding-top: 30px;
      box-sizing: border-box;
    }
    div.titleSubcontainer {
      display: block;
    }
    div.title {
      padding-top: 6px;
      text-align: center;
      font-size: 2.7rem;
      line-height: 1.48;
      letter-spacing: 0.32rem;
      padding-bottom: 32px;

    }
    div.clickTitle {
      margin-top: 24px;
      font-size: 1.5rem;
      line-height: 1.47;
      letter-spacing: 0.2rem;
    }
    div.subContainerBubbles {
      margin-top: 280px;
      padding-left: 72px;
      height: auto;
      padding-right: 72px;
      padding-bottom: 60px;
      box-sizing: border-box;
    }
    div.bubble2 {
      margin-top: 250px;
      margin-left: -110px;
    }
    div.bubble3 {
      margin-top: 48px;
      margin-left: 12px;
    }
    div.bubble4 {
      margin-top: 310px;
      margin-left: -25px;
    }
    div.bubble5 {
      margin-top: 20px;
      margin-left: -30px;
    } 
    div.bubble6 {
      margin-top: 267px;
      margin-left: -20px;
    }
    div.bubble7 {
      margin-top: -5px;
      margin-left: -30px;
    }
    div.bubble8 {
      margin-top: 300px;
      margin-left: -40px;
    }
    div.bubbleDots {
      background-size: contain;
    }
    div.bubbleDotsOne {
      width: 138px;
      height: 294px;
    }
    div.bubbleDotsTwo {
      width: 198px;
      height: 282px;
      top: -130px;
      left: 70px;
    }
    div.bubbleDotsThree {
      width: 142px;
      height: 270px;
      left: 96px;
      top: 60px;
    }
    div.bubbleDotsFour {
      width: 150px;
      height: 298px;
      left: 93px;
      top: -180px;
    }
    div.bubbleDotsFive {
      width: 134px;
      height: 258px;
      top: 85px;
      left: 66px;
    }
    div.bubbleDotsSix {
      width: 166px;
      height: 270px;
      top: -156px;
      left: 70px;
    }
    div.bubbleDotsSeven {
      width: 162px;
      height: 330px;
    }
    div.bubbleDotsEight {
      width: 204px;
      height: 286px;
      top: -160px;
      left: 60px;
    }
  }
}
@media (min-width: 1260px) { 
  div.container {
    padding-top: 90px;
    div.fixedPanel {
      position: initial;
      max-width: 920px;
      margin: 0 auto;
    }
    div.yourProgressTitle {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.47;
      letter-spacing: 0.2rem;
      color: #000000;
    }
    div.titleSubcontainer {
      display: block;
    }
    div.burgerContainer {
      position: absolute;
      top: 32px;
      left: 24px;
    }
    div.title {
      font-size: 2.7rem;
      line-height: 1.48;
      letter-spacing: 0.32rem;
      text-align: center;
      color: #000000;
    }
    div.containerProgressBar {
      margin-top: 32px;
    }
    div.clickTitle {
      margin-top: 24px;
      font-size: 1.5rem;
    }
    div.subContainerBubbles {
      max-width: 920px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 28px;
    }
    div.subContainerBubbles::-webkit-scrollbar {
      -webkit-appearance: none;
      cursor: pointer;
    }
    
    div.subContainerBubbles::-webkit-scrollbar {
      width: 4px;
      cursor: pointer;
    }
    
    div.subContainerBubbles::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    div.subContainerBubbles::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
}
.container {
  height: 100%;
  padding-top: 20px;
}
.containerTitle {
  padding-left: 4px;
}
.titleSubcontainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 26px;
  align-items: center;
}
.title {
  font-family: MonserratBold;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.18rem;
  color: black;
  text-align: right;
}
.containerProgressBar {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 16px;
  position: relative;
}
.progressBar {
  height: 100%;
  width: 100%;
  opacity: 0.2;
  background-color: #0b34d9;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
}

.progress {
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to left, #008ae8, #0052cc);
  position: absolute;
  z-index: 10;
  border-radius: 5px;
}

.clickTitle {
  margin-top: 8px;
  font-family: SFProTextRegular;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: center;
  color: #3d4f6c;
  text-align: center;
  box-sizing: border-box;
}
.subContainerBubbles {
  height: 420px;
  margin-top: 30px;
  position: absolute;
  padding-right: 0;
  margin-top: 124px;
  width: 100vw;
  overflow-x: scroll;
}

.bubble {
  float: left;
  position: relative;
}

.bubbleDots {
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: -1;  
}

.bubble2 {
  margin-top: 180px;
  margin-left: -80px;
}
.bubble3 {
  margin-top: 40px;
  margin-left: 0px;
}
.bubble4 {
  margin-top: 220px;
  margin-left: -35px;
}
.bubble5 {
  margin-top: 20px;
  margin-left: -25px;
}
.bubble6 {
  margin-top: 189px;
  margin-left: -24px;
}
.bubble7 {
  margin-top: 5px;
  margin-left: -30px;
}
.bubble8 {
  margin-top: 180px;
  margin-left: -20px;
}

.bubbleDotsOne {
  background-image: url('../../../images/sprites/bubbleDotsOne.svg');
  width: 84px;
  height: 178px;
  top: 93px;
  left: 7px;
}
.bubbleDotsTwo {
  background-image: url('../../../images/sprites/bubbleDotsTwo.svg');
  width: 120px;
  height: 170px;
  top: -80px;
  left: 58px;
}
.bubbleDotsThree {
  background-image: url('../../../images/sprites/bubbleDotsThree.svg');
  width: 86px;
  height: 172px;
  left: 60px;
  top: 52px;
}
.bubbleDotsFour {
  background-image: url('../../../images/sprites/bubbleDotsFour.svg');
  width: 90px;
  height: 180px;
  left: 59px;
  top: -141px;
}
.bubbleDotsFive {
  background-image: url('../../../images/sprites/bubbleDotsFive.svg');
  width: 88px;
  height: 155px;
  left: 58px;
  top: 60px;
}
.bubbleDotsSix {
  background-image: url('../../../images/sprites/bubbleDotsSix.svg');
  width: 100px;
  height: 161px;
  left: 55px;
  top: -107px;
}
.bubbleDotsSeven {
  background-image: url('../../../images/sprites/bubbleDotsSeven.svg');
  width: 96px;
  height: 198px;
  left: 58px;
  top: 65px;
}

.bubbleDotsEight {
  background-image: url('../../../images/sprites/bubbleDotsEight.svg');
  width: 121px;
  height: 170px;
  left: 60px;
  top: -80px;
}

.bubbleContainerActive {
  .bubbleDotsOne {
    background-image: url('../../../images/journey/dots-active-1.svg');
  }
  .bubbleDotsTwo {
    background-image: url('../../../images/journey/dots-active-2.svg');
  }
  .bubbleDotsThree {
    background-image: url('../../../images/journey/dots-active-3.svg');
  }
  .bubbleDotsFour {
    background-image: url('../../../images/journey/dots-active-4.svg');
  }
  .bubbleDotsFive {
    background-image: url('../../../images/journey/dots-active-5.svg');
  }
  .bubbleDotsSix {
    background-image: url('../../../images/journey/dots-active-6.svg');
  }
  .bubbleDotsSeven {
    background-image: url('../../../images/journey/dots-active-7.svg');
  }
  .bubbleDotsEight {
    background-image: url('../../../images/journey/dots-active-8.svg');
  }
}
.oval {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 100%;
  margin: 0 auto;
}
.fixedPanel {
  position: absolute;
  left: 24px;
  width: calc(100vw - 48px);
  box-sizing: border-box;
}
.bubblesList {
  width: max-content;
  padding: 5px 32px;
}
.yourProgressTitle {
  font-family: SFProTextRegular;
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.13rem;
  text-align: right;
  color: #3d4f6c;
}
.burgerContainer {
  padding-top: 2px;
}