@media screen and (orientation: landscape) {
  div.termText {
    height: 44vh;
  }
  div.buttonContainer {
    bottom: 16px;
    right: 16px;
  } 
  .container, div.containerBack {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media(orientation: landscape) {
    .container {
      div.termsTitle {
        padding-top: 24px;
      }
    }
  }
  @media (min-width: 1260px) {
    div.container {
      padding-top: 80px;
      box-sizing: border-box;
      padding-right: 20%;
      background-image: url('../../../images/bg-desktop-login.svg') !important;
    }
    div.buttonContainer {
      transform: translate(calc(50% + 200px), 0);
    }
    div.tabletBg {
      height: fit-content !important;
      div.termTextContainer {
        width: 350px;
        height: 320px;
        margin: 0 auto;
        overflow: hidden;
        box-sizing: content-box;
      }
      div.buttonContainer {
        bottom: 160px;
      }
      div.subContainer {
        background-image: none;
      }
    }
  }
  div.container {
    background-image: none;
    min-height: 100vh;
  }
  div.termsTitle {
    padding-top: 150px;
    width: 370px;
    margin: 0 auto;
    font-size: 6.4rem;
    line-height: 1.25;
    letter-spacing: 0.73rem;
    padding-left: 12px;
  }
  div.subContainer {
    height: 100%;
    background-image: url('../../../images/bg-terms-privacy-bubbles-white.svg');
    background-size: cover;
  }
  div.tabletBg {
    min-height: 100vh;
    height: 100%;
    background-image: linear-gradient(to top, #7430f8, #315bf2);
  }
  div.termText {
    width: 350px;
    padding: 0;
    margin: 0 auto;
    height: 320px;
    font-size: 1.5rem;
    line-height: 1.47;
    letter-spacing: 0.2rem;
    color: #ffffff;
  }
  div.termText::-webkit-scrollbar {
    -webkit-appearance: none;
    cursor: pointer;
  }

  div.termText::-webkit-scrollbar:vertical {
    width: 4px;
    cursor: pointer;
  }

  div.termText::-webkit-scrollbar-thumb {
    background-color: white;
    cursor: pointer;
  }
  div.termText::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
  }
  div.buttonContainer {
    cursor: pointer;
    position: relative;
    bottom: 0;
    right: 0;
    width: 248px;
    height: 248px;
    margin: 0 auto;
    box-shadow: none;
    border-radius: 100%;
    margin-top: 48px;
  }
}
@media (max-width: 368px) {
  div.termText {
    height: 36vh;
  }
}
.container {
  height: 100%;
  background-image: url('../../../images/auth/Bg-Terms & Privacy.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.subContainer, .tabletBg {
  height: 100%;
}
.termsTitle {
  font-size: 2.7rem;
  line-height: 4rem;
  letter-spacing: 0.32rem;
  font-family: MonserratBold;
  color: white;
  width: 100%;
  margin: 0 0 6px 0;
  padding-top: 56px;
  text-align: left;
  padding-left: 24px;
  padding-right: 12px;
  box-sizing: border-box;
}
.termText {
  font-size: 1.5rem;
  line-height: 2.2rem;
  letter-spacing: 0.2rem;
  color: white !important;
  font-family: SFProTextRegular;
  padding: 0 24px 0 24px;
  overflow-y: scroll;
  height: 52vh;

  span {
    color: white !important;
  }
}
.buttonContainer {
  bottom: 32px;
  right: 32px;
  position: absolute;
}
.containerBack {
  position: absolute;
  top: 24px;
  left: 20px;
}