@font-face {
  font-family: MonserratBold;
  src: url('./fonts/Montserrat-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: MonserratMedium;
  src: url('./fonts/Montserrat-Medium.ttf');
  font-display: swap;
}
@font-face {
  font-family: SFProTextRegular;
  src: url('./fonts/SFProText-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: SFProTextBold;
  src: url('./fonts/SFProText-Bold.ttf');
  font-display: swap;
}