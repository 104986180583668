
@media (min-width: 740px) and (max-width: 1366px) and (min-height: 740px) and (max-height: 1366px), (min-width: 1260px) and (min-height: 640px) {
  @media (min-width: 1260px) {
    div.container {
      height: auto;
      background-image: url('../../../images/bg-desktop-login.svg') !important;
      div.helloTitle {
        font-size: 6.4rem;
        line-height: 7.2rem;
        letter-spacing: 0.768rem;
        width: 568px;
      }
      div.positionBlock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
      }
      div.helloContainerButtons {
        button.helloButton:first-child {
          margin-top: 108px;
        }
      }
    }
  }
  div.container {
    min-height: 100vh;
    background-image: url('../../../images/auth/bg-new-user-exiting-bubbles-white.svg');
    background-color: #0b34d9ed;
  }
  div.subContainer {
    background-image: linear-gradient(to bottom, #179aec8c, #0b34d96e);
  }
  div.helloBlock {
    display: block;
    position: relative;
    bottom: 0;
    width: 100%;
    row-gap: 0;
    padding-top: 260px;
    min-height: 100vh;
    box-sizing: border-box;
  }
  div.helloTitle {
    font-size: 4rem;
    line-height: 1.5;
    letter-spacing: 0.476rem;
    width: 330px;
    margin: 0 auto;
    font-family: MonserratBold;
    padding-bottom: 0;
  }
  div.helloContainerButtons {
    padding-top: 0;
    width: 420px;
    margin: 0 auto;
    display: block;
    position: relative;
    margin-top: 28px;
    button.helloButton {
      width: 160px;
      height: 160px;
      border-radius: 100%;
      font-size: 1.5rem;
      line-height: 1.5;
      letter-spacing: 0.179rem;
      padding: 9px 22px 10px 24px;

      &:nth-child(2) {
        margin-top: -10px;
      }
    }
    button.helloButton:first-child {
      margin-top: 56px;
      margin-right: 24px;
    }
  }
  .helloNewButton, .helloContinueButton {
    background-image: url('./../../../images/login-active-button.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 228px;
    height: 228px;
    position: absolute;

    &:hover {
      background-image: url('./../../../images/login-hover-button.svg');
    }
    span {
      width: 160px;
      height: 160px;
      border-radius: 100%;
      display: block;
      font-family: MonserratBold;
      font-size: 1.5rem;
      line-height: 1.5;
      letter-spacing: 0.179rem;
      text-align: center;
      color: #000000;
      box-sizing: border-box;
      margin-top: 4px;
      margin-left: 48px;
      cursor: pointer;
    }
  }
  .helloNewButton {
    left: 0;
    top: 56px;

    span {
      padding-top: 58px;
    }
  }
  .helloContinueButton {
    position: absolute;
    right: 0;

    span {
      padding-top: 42px;
    }
  }
}
@media (max-width: 368px) {
  div.helloTitle {
    padding-bottom: 60px;
  }
}
.container {
  height: 100%;
  background-image: url('../../../images/auth/Bg-New-Existing_User.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.subContainer {
  height: 100%;
}
.helloTitle {
  font-size: 1.5rem;
  line-height: 2.2rem;
  letter-spacing: 0.2rem;
  text-align: center;
  width: 100%;
  color: white;
  font-family: SFProTextRegular;
  padding-bottom: 60px;
}
.helloContainerButtons {
  display: grid;
  justify-content: center;
  width: 100%;
  row-gap: 24px;
}
button.helloButton {
  font-size: 1.1rem;
  letter-spacing: 0.13rem;
  padding: 11px 24px 10px 24px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  text-transform: initial;
  color: black;
  font-family: MonserratBold;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
    background-color: #efefef;
  }
}
.helloBlock {
  display: grid;
  position: absolute;
  bottom: 40px;
  width: 100%;
  row-gap: 90px;
}